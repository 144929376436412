import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { isAuthenticated } from './services/auth'

import Login from './Login'
import View from './View'

const PrivateRoute = ({ component:  Component, ...rest }) => (
    <Route {...rest} render={props => (
        isAuthenticated() ? (
            <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/login', state: {from: props.location }}} />
        )
    )} />
);

const Routes = () => {
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute path="/" component={View} />
            </Switch>
        </BrowserRouter>
    );
}

export default Routes;
