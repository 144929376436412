export const TOKEN_KEY = "@yupper-Preview";
export const isAuthenticated = () => {
  if (localStorage.getItem(TOKEN_KEY) === null) {
    return null
  } else {
    return true
  }
}
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};