import React,  { useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import './view.css'
import Clients from '../clients.json'

const View = () => {
    const location = useLocation();
    const iframeRef = useRef();
    const siteName = localStorage.getItem('project_name')    
    const [loading, setLoading] = useState(true);
    const [published, setPublished] = useState(false);

    let client = Clients.filter((item) => {
        return siteName === item.client
    })

    const handlePublish = (event) => {
        event.preventDefault();
        setPublished(true)

        const requestOptions = {
            method: 'POST'
        }

        fetch(client[0].deploy, requestOptions)
        .then(response => console.log(response))
        setTimeout(() => { 
            setPublished(false)
        }, 20000);
    }
    
    return(
        <div className="view">
            { loading ? 
                <div className="view__loading">
                    <div className="sk-chase">
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                    </div>
                    <h3>Carregando o site</h3>
                </div>
                : null }

            <iframe
                src={ client[0].previewUrl }
                width="100%"
                height="100%"
                frameBorder="0"
                marginWidth="0"
                marginHeight="0"
                title="Client Preview"
                ref={ iframeRef }
                onError={ () => location.reload() }
                onLoad={ () => setLoading(false) }
            />

            <div className={ `view__bottom ${ loading ? '' : 'active' }`}>
                <p>Preview mode. Clique em publicar quando estiver pronto.</p>
                <button onClick={ handlePublish }>Publicar</button>
            </div>
            
            { published ? 
                <div className="view__published">
                    <div className="view__published__container">
                        <div className="sk-chase">
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                        </div>
                        <h3>O site está sendo publicado...</h3>
                        <p>Isto leva em média 2 minutos.</p>
                    </div>
                </div>
            : null }

        </div>
    )
}

export default View;
