import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import './login.css'
import logo from './logo.svg'
import Clients from '../clients.json'
import { login } from '../services/auth'

const Login = () => {
    let history = useHistory()
    const location = useLocation()
    const siteName = location.search.slice(1)

    const [message, setMessage] = useState(null);

    const { handleSubmit, register, errors } = useForm();

    const onSubmit = values => {
        const formSite = values.site
        const formPass = values.password

        let verify = Clients.filter((item) => {
            return formSite === item.client && formPass === item.password
        })
        
        if (verify.length > 0)  {
            login('8NjYsB4y4fwhavpmQsxt')
            localStorage.setItem('project_name', verify[0].client);
            history.push(`/${verify[0].client}`)
        } else {
            setMessage('Senha inválida')
        }
    };

    return (
        <div className="login">
            <h1><img src={logo} alt="Yupper Preview" /></h1>

            <div className="login__container">
                <h3>Preview</h3>


                <form onSubmit={handleSubmit(onSubmit)}>
                    <p className="form__row">
                        <label>Your site</label>
                        <input
                            name="site"
                            defaultValue={ siteName }
                            ref={register({
                                required: 'Obrigatório'
                            })}
                        />
                        { errors.site &&
                            <span>{ errors.site.message }</span>
                        }
                    </p>

                    <p className="form__row">
                        <label>Password</label>
                        <input
                            name="password"
                            type="password"
                            ref={register({
                                required: 'Obrigatório'
                            })}
                        />
                        
                        { errors.password &&
                            <span>{ errors.password.message }</span>
                        }
                        
                        { message &&
                            <span>{ message }</span>
                        }
                    </p>

                    <p className="form__row">
                        <button type="submit" className="btn">Access preview</button>
                    </p>
                </form>
            </div>
        </div>
    )
}


export default Login
